import React from 'react'

import PropTypes from 'prop-types'

import './nav-links.css'

const NavLinks = (props) => {
  return (
    <div className="nav-links-links">
      <a className="navLink" href="#about-us">{props.about_us}</a>
      <a className="navLink" href="#services">{props.services}</a>
      <a className="navLink" href="#contacts">{props.contact}</a>
    </div>
  )
}

NavLinks.defaultProps = {
  services: 'services',
  about_us: 'about us',
  contact: 'contact',
}

NavLinks.propTypes = {
  services: PropTypes.string,
  about_us: PropTypes.string,
  contact: PropTypes.string,
}

export default NavLinks
