import React from 'react'

import { Helmet } from 'react-helmet'

import NavLinks from '../components/nav-links'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>WisdChat</title>
        <meta property="og:title" content="WisdChat" />
      </Helmet>
      <div className="home-navbar">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <div className="home-container1">
            <span className="home-text">
              <span>Wisd</span>
              <br></br>
              <span className="home-text03">Chat</span>
            </span>
          </div>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="home-nav"
            >
              <div className="home-right">
                <NavLinks></NavLinks>
                <div className="home-button">
                  <a className="home-work-with-us button" href="https://wa.me/19147092128">
                    <span className="home-text04">start a chat</span>
                    <img
                      alt="image"
                      src="/hamburger.svg"
                      className="home-image01"
                    />
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <a className="home-work-with-us1 button">
              <span className="home-text05">start a chat</span>
              <img alt="image" src="/hamburger.svg" className="home-image02" />
            </a>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav1"
            >
              <div className="home-top">
                <div className="home-branding">
                  <span className="home-text06">WisdChat</span>
                </div>
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-middle"
              >
                <div className="home-links">
                  <a className="navLink" href="#about-us">about us</a>
                  <a className="navLink"  href="#services">services</a>
                  <a className="navLink" href="#contacts">contact</a>
                </div>
                <a className="home-work-with-us2 button" href="https://wa.me/16017487878">
                  <span className="home-text07">start a chat</span>
                </a>
              </nav>
            </div>
          </div>
        </header>
      </div>
      <div className="home-hero" id="about-us">
        <div className="home-header">
          <h1 className="home-text08">
            We provide our users with convenient chatbots to which they can ask
            vital questions and get possible answers to them.
          </h1>
        </div>
        <div className="home-hero-image">
          <img alt="image" src="/back00.png" className="home-image04" />
        </div>
      </div>
      <div className="home-who">
        <div className="home-header1">
          <div className="home-heading">
            <h2 className="home-text09">
              Your daily assistance with AI technology.
            </h2>
            <span className="home-text10">
              Whether you&apos;re wondering what could your last dream mean, how
              to change your lifestyle to achieve your goals, or where to find a
              further motivation -- just describe your situation and get
              response from the power of modern artificial intelligence.
            </span>
          </div>
          <a className="home-button1 button" href="https://wa.me/16017487878">let’s chat</a>
        </div>
      </div>
      <div className="home-section" id="services">
        <div className="home-case-studies">
          <div className="home-caption">
            <span className="home-text11">Services</span>
          </div>
          <div className="home-gallery">
            <div className="home-row">
              <img
                alt="image"
                src="/dream0.png"
                className="home-image07"
              />
              <div className="home-case">
                <div className="home-heading1">
                  <span className="home-text12">Dream Interpreter</span>
                  <span className="home-text13">
                    Better understand your dreams.
                  </span>
                </div>
              </div>
            </div>
            <div className="home-row1">
              <img
                alt="image"
                src="/coach0.png"
                className="home-image07"
              />
              <div className="home-case1">
                <div className="home-heading2">
                  <span className="home-text14">Life Coach</span>
                  <span className="home-text15">
                    Make better decisions and reach your objectives.
                  </span>
                </div>
              </div>
            </div>
            <div className="home-row">
              <img
                alt="image"
                src="/sage0 copy.png"
                className="home-image09"
              />
              <div className="home-case2">
                <div className="home-heading3">
                  <span className="home-text16">Opinion of the Sage</span>
                  <span className="home-text17">
                    Get an advice from the sage.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="home-footer" id="contacts">
        <div className="home-locations">
          <div className="home-location">
            <span className="home-title">WisdChat</span>
            <span className="home-details">
              <span>2023</span>
              <br></br>
              <span>wisd.chat@gmail.com</span>
            </span>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
